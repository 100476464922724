export enum FieldMaxLength {
  CAPTURE_TITLE = 255,
  CAPTURE_STUDENT_NAME = 255,

  FAVORITE_DIRECTORY_NAME = 255,

  INSTITUTION_NAME = 255,
  INSTITUTION_ADDRESS_STREET = 255,
  INSTITUTION_ADDRESS_ZIP = 255,
  INSTITUTION_ADDRESS_CITY = 255,
  INSTITUTION_ADDRESS_REGION = 255,
  INSTITUTION_ADDRESS_PHONE = 255,

  USER_USERNAME = 180,
  USER_FIRST_NAME = 255,
  USER_LAST_NAME = 255,
  USER_EMAIL = 255,
}